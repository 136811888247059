import { AlertCircle, Copy, HelpCircle } from 'react-feather'
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
  Tooltip
} from 'reactstrap'
import React, { useEffect, useMemo, useState } from 'react'
import Select, { components } from 'react-select'
import { useDispatch, useSelector } from 'react-redux'

import { CopyToClipboard } from 'react-copy-to-clipboard'
import PaymentService from '../../../../network/services/payment'
import QRCode from 'react-qr-code'
import Recommended from './RecommendedTest2.svg'
import SweetAlert from 'sweetalert2'
import _ from 'lodash'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { useFormContext } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// import ERC20Icon from './erc20.png'

const ResponsiveCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 575px) {
    flex-direction: column;
    align-items: center;
  }
`

const ResponsiveVerifyButton = styled(Button)`
  background-color: #103c5a !important;
  border-color: #103c5a !important;
  margin-left: 12px;
  @media (max-width: 575px) {
    align-self: center;
  }
`

const ResponsiveParagraph = styled.p`
  margin: 0;
  text-align: center;
  @media (max-width: 575px) {
    margin-bottom: 8px;
  }
`
const ChooseAmount = () => {
  const { t } = useTranslation()
  const deposit = useSelector((state) => state.deposit)
  const wallets = useSelector((state) => state.wallet.wallets)
  const payments = useSelector((state) => state.payment.payments)
  const selectedWalletId = useSelector((state) => state.deposit.wallet.id)
  const ercWalletAddress = useSelector((state) => state.user.user.ethereum_wallet_address)
  const trcWalletAddress = useSelector((state) => state.user.user.tron_wallet_address)
  // const depositCurrency = useSelector((state) => state.depositCurrency.deposit_currency)
  const [selectedCurrency, setSelectedCurrency] = useState()
  const [baseCurrency, setBaseCurrency] = useState('')
  const [availableCurrencies, setAvailableCurrencies] = useState([])
  const [maxDeposit, setMaxDeposit] = useState(0)
  const [minDeposit, setMinDeposit] = useState(0)
  const [bankTransferPayments, setBankTransfersPayments] = useState([
    'mcpayment',
    'awepay',
    'mcpayment_ewallet',
    'mcpayment_credit_card',
    'epay'
  ])

  const [tooltipOpen, setTooltipOpen] = useState(false)
  const toolTiptoggle = () => setTooltipOpen(!tooltipOpen)

  const allCreditCard = useSelector((state) => state.user.credit_cards)
  const approvedCreditCard = allCreditCard?.filter((creditcard) => creditcard?.status === 1)
  //true then allow open deposit form, currently only false when no active credit card, is set at useEffect below
  const [selectedPaymentHandle, setselectedPaymentHandle] = useState(
    deposit?.wallet?.method?.handle
  )
  const [multichainFeesResult, setMultichainFeesResult] = useState([])
  const selectedPayment = useMemo(() => {
    const payment = payments.find((item) => item.handle == selectedPaymentHandle)
    if (payment?.supportedCurrencies?.length > 0) {
      const paymentCurrencies = payment?.supportedCurrencies
        ?.filter((item) => item.allow_deposit == true)
        .map((item) => item.currency)

      setAvailableCurrencies(paymentCurrencies)
      setBaseCurrency(paymentCurrencies[0].base_currency)
      setSelectedCurrency(paymentCurrencies[0].id)
      const currencyDetails = payment?.supportedCurrencies?.find(
        (item) => item.currency_id == paymentCurrencies[0].id
      )
      if (currencyDetails) {
        setMaxDeposit(currencyDetails.deposit_limit)
        setMinDeposit(currencyDetails.min_deposit ?? 100)
      }
    }

    return payments.find((e) => e.id === deposit?.wallet?.method?.id)
    // eslint-disable-next-line
  }, [deposit])

  const selectedWallet = useMemo(() => {
    return wallets.find((e) => e.id === selectedWalletId)
    // eslint-disable-next-line
  }, [selectedWalletId])

  const history = useHistory()
  const dispatch = useDispatch()
  const navigate = (path) => {
    history.push(path)
    dispatch({ type: 'SET_ACTIVE_MENU', path: 'none' })
  }

  // form
  const { register, errors, formState, watch } = useFormContext()
  const { isSubmitting } = formState
  const watchAmount = watch('amount')

  const handleClick = (e) => {
    const currency = availableCurrencies.find((item) => item.id == e.target.value)
    setBaseCurrency(currency.base_currency ?? 'USD')
    setSelectedCurrency(parseInt(e.target.value))

    const payment = payments.find((item) => item.handle == selectedPaymentHandle)
    const currencyDetails = payment?.supportedCurrencies?.find(
      (item) => item.currency_id == parseInt(e.target.value)
    )
    if (currencyDetails) {
      setMaxDeposit(currencyDetails.deposit_limit)
      setMinDeposit(currencyDetails.min_deposit ?? 100)
    }
  }

  // modal
  const [showAlertModal, setShowAlertModal] = useState(false)
  const toggleModal = () => {
    setShowAlertModal(!showAlertModal)
  }

  const [selectedUSDT, setSelectedUSDT] = useState('')
  const [selectedBlockChain, setSelectedBlockChain] = useState([])

  const handleUsdtChange = async (e) => {
    setTimer(3)
    setSelectedUSDT(e.value)
    const blockchain = availableCurrencies.find((item) => item.id == e.value)
    if (blockchain) {
      setSelectedBlockChain(blockchain.unit)
    }
    const network = blockchain.unit.includes('ERC') ? 'ERC20' : 'TRC20'
    try {
      const fees = await PaymentService.getMultichainDepositFees({ network: network })
      // console.log(fees)
      setMultichainFeesResult(fees)
    } catch (error) {
      const errorCode = error.code
      const errorTranslate = error.translate_params
      SweetAlert.fire({
        title: t(errorCode, errorTranslate),
        icon: 'error'
      })
    }
    toggleModal()
  }

  const [timer, setTimer] = useState(3)
  useEffect(() => {
    // exit early when we reach 0
    if (!timer) return

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimer(timer - 1)
    }, 1000)

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId)
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timer])
  const multiChainOption = availableCurrencies?.map((currency) => {
    if (currency.unit === 'USDT(TRC20)') {
      return {
        value: currency.id,
        label: currency.unit,
        icon: Recommended
      }
    } else {
      return {
        value: currency.id,
        label: currency.unit,
        icon: ''
      }
    }
  })

  const { Option } = components
  const IconOption = (props) => (
    <Option {...props}>
      {props.data.label}
      <img
        src={props.data.icon ? './' + props.data.icon : ''}
        style={{ width: 85, height: 45, marginLeft: 10 }}
        alt={props.data.label}
        onError={(event) => (event.target.style.display = 'none')}
      />
    </Option>
  )

  const checkWalletAddress = () => {
    return (
      <>
        {selectedBlockChain !== [] && (
          <Card style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', marginTop: '25px' }}>
            <CardBody>
              <Row style={{ alignItems: 'center' }}>
                <Col lg="5">
                  <QRCode
                    value={selectedBlockChain.includes('ERC') ? ercWalletAddress : trcWalletAddress}
                  />
                </Col>

                <Col lg="7">
                  <div className="mb-3">
                    <Label style={{ marginBottom: '0px', color: 'grey' }}>
                      {t('financial.network')}
                    </Label>
                    <h6 style={{ fontWeight: '400' }}>
                      <img
                        style={{ width: '55px', height: '40px' }}
                        src={
                          selectedBlockChain.includes('ERC')
                            ? 'https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/USDT+Icon-03.svg'
                            : 'https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/USDT+Icon-04.svg'
                        }
                        alt={selectedBlockChain.includes('ERC') ? 'erc20' : 'trc20'}
                      />
                      {selectedBlockChain}
                    </h6>
                  </div>

                  <div className="mb-3">
                    <Label style={{ marginBottom: '0px', color: 'grey' }}>
                      {t('financial.walletAddress')}
                    </Label>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                      <AddressH6>
                        {selectedBlockChain.includes('ERC') ? ercWalletAddress : trcWalletAddress}
                      </AddressH6>
                      <CopyToClipboard
                        text={
                          selectedBlockChain.includes('ERC') ? ercWalletAddress : trcWalletAddress
                        }
                      >
                        <Copy
                          style={{ cursor: 'pointer', color: '#f9b600' }}
                          onClick={() => {
                            toast.success(t('dashboard.linkCopiedToClipboard'), {
                              position: toast.POSITION.BOTTOM_RIGHT
                            })
                          }}
                        />
                      </CopyToClipboard>
                    </div>
                  </div>

                  <div className="mb-3">
                    <Label style={{ marginBottom: '0px', color: 'grey' }}>
                      {t('financial.depositFee')}
                    </Label>
                    <h6 style={{ fontWeight: '400' }}>
                      {/* {t('financial.depositFeeDetails', {
                        deposit_fee: multichainFeesResult?.deposit_fee,
                        charge_percentage: multichainFeesResult?.charge_percentage.toFixed(2)
                      })} */}
                      <span style={{ color: '#f9b600' }}>
                        {multichainFeesResult?.deposit_fee} USDT
                      </span>{' '}
                      +{' '}
                      <span style={{ color: '#f9b600' }}>
                        {multichainFeesResult?.charge_percentage?.toFixed(2)}%
                      </span>{' '}
                      {t('financial.ofTheDepositAmount')}
                    </h6>
                  </div>

                  {/* <div className="mb-3">
              <Label style={{ marginBottom: '0px', color: 'grey' }}>Platform Fee</Label>
              <h6 style={{ fontWeight: '400' }}>0.50 %</h6>
            </div> */}

                  <div className="mb-3">
                    <Label style={{ marginBottom: '0px', color: 'grey' }}>
                      {t('financial.minimumDeposit')}
                    </Label>
                    <h6 style={{ fontWeight: '400' }}>
                      {/* {t('financial.minimumDepositToBeReflected', {
                        minimum_deposit: multichainFeesResult?.minimum_deposit
                      })} */}
                      <span style={{ color: '#f9b600' }}>
                        {multichainFeesResult?.minimum_deposit} USDT {t('financial.orMore')}{' '}
                      </span>{' '}
                      {t('financial.toBeReflectedInBalance')}
                    </h6>
                  </div>
                </Col>
              </Row>

              <div className="mt-4">
                <Label style={{ marginBottom: '0px', color: '#ff0f0f' }}>
                  {t('financial.importantNotes')}
                </Label>
                <p style={{ marginBottom: '5px' }}>
                  1.{' '}
                  {t('financial.depositWillBeCredited', {
                    deposit_fee: multichainFeesResult?.deposit_fee,
                    charge_percentage: multichainFeesResult?.charge_percentage?.toFixed(2)
                  })}
                </p>
                <p>
                  2.{' '}
                  {t('financial.minimumDepositMustBe', {
                    minimum_deposit: multichainFeesResult?.minimum_deposit
                  })}
                </p>
              </div>
            </CardBody>
          </Card>
        )}
      </>
    )
  }

  return (
    <>
      {selectedPaymentHandle != 'mcpayment_credit_card' ||
      (selectedPaymentHandle == 'mcpayment_credit_card' && approvedCreditCard.length > 0) ? (
        <Card>
          <CardHeader>
            <h5>{t('financial.chooseAmount')}</h5>
            <p style={{ margin: 0 }}>{`${t(
              'financial.availableBalance'
            )} ${selectedWallet?.balance?.toFixed(2)} ${selectedWallet?.currency?.name}`}</p>
            {bankTransferPayments.includes(selectedPaymentHandle) == true && (
              <span style={{ color: 'red' }}>{t('financial.warningMakesureYourCard')}</span>
            )}
            {selectedPaymentHandle !== 'wire' && selectedPaymentHandle === 'bluepay' ? (
              <span style={{ color: 'green' }}>
                {`Rate of ${
                  (selectedPayment?.supportedCurrencies[0]?.currency?.exchange_rate).toFixed(2) *
                  (selectedPayment?.supportedCurrencies[0]?.currency?.deposit_rate).toFixed(2)
                } ${
                  selectedPayment?.supportedCurrencies[0]?.currency?.unit
                } per ${selectedPayment?.supportedCurrencies[0]?.currency?.base_currency?.toUpperCase()}`}
              </span>
            ) : (
              ''
            )}

            {selectedPaymentHandle === 'wire' ? (
              <span style={{ color: 'green' }}>{t('financial.rateDependsOnBank')}</span>
            ) : (
              ''
            )}
          </CardHeader>
          <CardBody>
            <Row>
              <Col>
                {selectedPaymentHandle == 'mcpayment_credit_card' && (
                  <FormGroup>
                    <Label>{t('financial.SelectCreditCard')}</Label>
                    <Input
                      type="select"
                      name="credit_card_id"
                      innerRef={register({ required: true })}
                      // style={{ width: '50%' }}
                      required
                      className="mb-2"
                    >
                      <option value="">{t('financial.PleaseSelectCreditCard')}</option>
                      {
                        availableCurrencies?.length > 0 &&
                          selectedPaymentHandle === 'mcpayment_credit_card' &&
                          approvedCreditCard.map((creditCard) => {
                            return (
                              <option value={creditCard.id}>
                                {creditCard?.card_number.substring(0, 4) +
                                  ' **** **** ' +
                                  creditCard?.card_number.substring(15, 19)}
                              </option>
                            )
                          })

                        // <option value={mcpaymentCreditCardCurrencies[randomNumber - 1].id}>{mcpaymentCreditCardCurrencies[randomNumber - 1].abbreviation}</option>
                      }
                    </Input>
                  </FormGroup>
                )}
                {bankTransferPayments.includes(selectedPaymentHandle) == true && (
                  <>
                    <FormGroup>
                      <Label>{t('financial.currency')}</Label>
                      <Input
                        type="select"
                        name="currency_id"
                        innerRef={register({ required: true })}
                        // style={{ width: '50%' }}
                        required
                        onChange={handleClick}
                        className="mb-2"
                      >
                        {availableCurrencies?.length > 0 &&
                          availableCurrencies?.map((currency) => {
                            return <option value={currency.id}>{currency.abbreviation}</option>
                          })}
                      </Input>
                    </FormGroup>

                    {/* For awepay */}
                    <Row>
                      {selectedPaymentHandle !== 'wire' &&
                      bankTransferPayments.includes(selectedPaymentHandle) == true ? (
                        <Col lg="6">
                          <FormGroup>
                            <Label>
                              {t('financial.depositAmount')}
                              <span
                                style={{ color: 'green', textTransform: 'uppercase' }}
                              >{`(${baseCurrency})`}</span>
                            </Label>
                            <Input
                              className="form-control"
                              type="number"
                              step=".01"
                              name="amount"
                              innerRef={register({
                                required: t('financial.amountIsRequired'),
                                min: {
                                  value: minDeposit ?? 100,
                                  message: t('financial.minimumDepositAmount', {
                                    min_deposit: minDeposit.toFixed(2)
                                  })
                                }
                              })}
                            />
                            <span style={{ color: 'red', display: 'block' }}>
                              {errors.amount && errors.amount.message}
                            </span>

                            <p />
                            {selectedPaymentHandle !== 'wire' &&
                            selectedPaymentHandle === 'bluepay' ? (
                              <span style={{ color: 'green' }}>
                                {`~ ${t(
                                  'financial.youWillNeedToPay'
                                )} ${selectedPayment?.supportedCurrencies[0]?.currency?.abbreviation?.toUpperCase()}  ${(
                                  watchAmount *
                                  selectedPayment?.supportedCurrencies[0]?.currency?.exchange_rate
                                ).toFixed(2)}
                            ${t('financial.toReceive')} $ ${
                                  watchAmount > 0
                                    ? watchAmount *
                                      selectedPayment?.supportedCurrencies[0]?.currency
                                        ?.deposit_rate
                                    : '0.00'
                                }
                              ${t('financial.inWallet')}`}
                              </span>
                            ) : (
                              ''
                            )}
                          </FormGroup>
                        </Col>
                      ) : (
                        ''
                      )}

                      {/* Hide temporary  */}
                      {bankTransferPayments.includes(selectedPaymentHandle) == true &&
                        bankTransferPayments.includes(selectedPaymentHandle) == true &&
                        availableCurrencies?.length > 0 &&
                        availableCurrencies.map((currency) => {
                          if (currency.id === selectedCurrency) {
                            if (
                              (selectedPaymentHandle == 'mcpayment_credit_card' &&
                                currency.id != 1) ||
                              selectedPaymentHandle != 'mcpayment_credit_card'
                            ) {
                              return (
                                <>
                                  <Col lg="6">
                                    <FormGroup>
                                      <Label>
                                        {t('financial.paymentAmount')}{' '}
                                        <span style={{ color: 'green' }}>
                                          ({currency?.abbreviation})
                                        </span>
                                      </Label>
                                      <Input
                                        type="text"
                                        value={(watchAmount * currency?.exchange_rate).toFixed(2)}
                                        disabled
                                        style={{ backgroundColor: '#f8f8f8' }}
                                      ></Input>
                                    </FormGroup>
                                  </Col>
                                </>
                              )
                            }
                          }
                        })}
                    </Row>

                    <Row>
                      {selectedPaymentHandle == 'mcpayment_credit_card' &&
                        availableCurrencies?.length > 0 &&
                        availableCurrencies.map((currency) => {
                          if (currency.id === selectedCurrency) {
                            return (
                              <>
                                <Col lg="6">
                                  <FormGroup>
                                    <Label>Deposit Fee</Label>
                                    <Input
                                      type="text"
                                      value={selectedPayment?.charge_multiplier * 100 + '%'}
                                      disabled
                                      style={{ backgroundColor: '#f8f8f8' }}
                                    ></Input>
                                  </FormGroup>
                                </Col>
                              </>
                            )
                          }
                        })}

                      {selectedPaymentHandle == 'mcpayment_credit_card' &&
                        availableCurrencies?.length > 0 &&
                        availableCurrencies.map((currency) => {
                          if (currency.id === selectedCurrency) {
                            return (
                              <>
                                <Col lg="6">
                                  <FormGroup>
                                    <Label>
                                      Receive Amount{' '}
                                      <span style={{ color: 'green' }}>
                                        ({currency?.abbreviation})
                                      </span>
                                    </Label>
                                    <Input
                                      type="text"
                                      value={
                                        watchAmount &&
                                        (
                                          parseInt(watchAmount) *
                                          (1 - selectedPayment?.charge_multiplier)
                                        ).toFixed(2)
                                      }
                                      disabled
                                      style={{ backgroundColor: '#f8f8f8' }}
                                    ></Input>
                                  </FormGroup>
                                </Col>
                              </>
                            )
                          }
                        })}
                    </Row>
                  </>
                )}

                {/* Malayisa currency */}
                {/* {selectedPaymentHandle === 'mcpayment' && selectedCurrency === 2 && (
                <p style={{ color: 'red' }}>
                  {t('financial.MaximumDeposit')} 1000USD {t('financial.perTransaction')}
                </p>
              )} */}
                {maxDeposit && maxDeposit != 9999999 && (
                  <p style={{ color: 'red' }}>
                    {t('financial.MaximumDeposit')} {maxDeposit.toFixed(2)}USD{' '}
                    {t('financial.perTransaction')}
                  </p>
                )}

                {bankTransferPayments.includes(selectedPaymentHandle) == true &&
                  availableCurrencies?.length > 0 &&
                  availableCurrencies.map((currency) => {
                    if (currency.id === selectedCurrency) {
                      return (
                        <div>
                          {t('financial.CurrentRateOf1')} {currency?.base_currency?.toUpperCase()} ={' '}
                          {(currency?.exchange_rate).toFixed(2)} {currency?.abbreviation}
                        </div>
                      )
                    }
                  })}

                {/* End of awepay */}

                {/* For Bluepay usdt */}
                {selectedPaymentHandle !== 'wire' && selectedPaymentHandle === 'bluepay' ? (
                  <FormGroup>
                    <Label>
                      {t('financial.depositAmount')}
                      <span
                        style={{ color: 'green', textTransform: 'uppercase' }}
                      >{` (${selectedPayment?.supportedCurrencies[0]?.currency.abbreviation?.toUpperCase()})`}</span>
                    </Label>

                    <Input
                      type="text"
                      name="currency_id"
                      innerRef={register({ required: true })}
                      hidden
                      value={selectedPayment?.supportedCurrencies[0]?.currency?.id}
                    ></Input>

                    <Input
                      className="form-control"
                      type="number"
                      step=".01"
                      name="amount"
                      innerRef={register({
                        required: t('financial.amountIsRequired'),
                        min: {
                          value: minDeposit ?? 100,
                          message: t('financial.minimumDepositAmount', {
                            min_deposit: minDeposit.toFixed(2)
                          })
                        }
                      })}
                    />
                    <span style={{ color: 'red', display: 'block' }}>
                      {errors.amount && errors.amount.message}
                    </span>

                    <p />
                    {selectedPaymentHandle !== 'wire' && selectedPaymentHandle === 'bluepay' ? (
                      <span style={{ color: 'green' }}>
                        {`~ ${t(
                          'financial.youWillNeedToPay'
                        )} ${selectedPayment?.supportedCurrencies[0]?.currency?.abbreviation?.toUpperCase()}  ${(
                          watchAmount *
                          selectedPayment?.supportedCurrencies[0]?.currency?.exchange_rate
                        ).toFixed(2)}
                  ${t('financial.toReceive')} $ ${
                          watchAmount > 0
                            ? watchAmount *
                              selectedPayment?.supportedCurrencies[0]?.currency?.deposit_rate
                            : '0.00'
                        }
                    ${t('financial.inWallet')}`}
                      </span>
                    ) : (
                      ''
                    )}
                  </FormGroup>
                ) : (
                  ''
                )}

                {/* End of Blue pay */}
                {/* New payment */}
                {selectedPaymentHandle !== 'wire' && selectedPaymentHandle === 'multichain' ? (
                  <FormGroup>
                    <Label>{t('financial.chooseNetwork')}</Label>

                    {/* <Input
                    type="select"
                    name="usdt_id"
                    innerRef={register({ required: true })}

                    required
                    onChange={(e) => {
                      handleUsdtChange(e)
                    }}
                    className="mb-5"
                  >
                    <option value="" disabled={selectedBlockChain != null ? true : false}>
                      {t('financial.pleaseSelectANetwork')}
                    </option>

                    {availableCurrencies.length > 0 &&
                      availableCurrencies.map((currency) => (
                        <option value={currency.id}>{currency.unit}</option>
                      ))}
                  </Input> */}

                    <Select
                      name="usdt_id"
                      innerRef={register({ required: true })}
                      options={multiChainOption}
                      onChange={(e) => {
                        handleUsdtChange(e)
                        // console.log(e)
                      }}
                      components={{ Option: IconOption }}
                    />

                    {selectedUSDT !== '' && (
                      <Modal isOpen={showAlertModal} backdrop={true} centered={true}>
                        <ModalBody style={{ textAlign: 'center' }}>
                          {/* <AlertCircle centered={true} width="75px" height='75px' style={{ color: '#f9b600', marginBottom: '25px' }} /> */}
                          {selectedBlockChain === 'USDT(ERC20)' && (
                            <img
                              style={{ width: '200px', height: '200px', marginBottom: '25px' }}
                              src="https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/USDT+Icon-03.svg"
                              alt="erc20"
                            />
                          )}
                          {selectedBlockChain === 'USDT(TRC20)' && (
                            <img
                              style={{ width: '200px', height: '200px', marginBottom: '25px' }}
                              src="https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/USDT+Icon-04.svg"
                              alt="trc20"
                            />
                          )}
                          <h4>
                            {t('financial.selected')}{' '}
                            <span style={{ color: '#f9b600' }}>{selectedBlockChain}</span>
                          </h4>
                          <p style={{ color: 'grey' }}>{t('financial.ensureTheNetwork')}</p>
                          <div>
                            <Button
                              style={{ marginTop: '15px' }}
                              disabled={timer !== 0}
                              onClick={() => {
                                toggleModal()
                              }}
                            >
                              {t('financial.IUnderstand')} {timer > 0 && timer}
                            </Button>
                          </div>
                        </ModalBody>
                      </Modal>
                    )}

                    {selectedBlockChain && selectedUSDT !== '' && checkWalletAddress()}

                    <span style={{ color: 'red', display: 'block' }}>
                      {errors.amount && errors.amount.message}
                    </span>

                    <p />
                  </FormGroup>
                ) : (
                  ''
                )}

                {/* For Wire */}
                {selectedPaymentHandle === 'wire' ? (
                  <FormGroup>
                    <Label>
                      {t('financial.depositAmount')}
                      <span
                        style={{ color: 'green', textTransform: 'uppercase' }}
                      >{` (${selectedPayment?.supportedCurrencies[0].currency?.base_currency})`}</span>
                    </Label>

                    <Input
                      type="text"
                      name="currency_id"
                      innerRef={register({ required: true })}
                      hidden
                      value={selectedPayment?.supportedCurrencies[0].currency?.id}
                    ></Input>

                    <Input
                      className="form-control"
                      type="number"
                      step=".01"
                      name="amount"
                      innerRef={register({
                        required: t('financial.amountIsRequired'),
                        min: {
                          value: minDeposit ?? 100,
                          message: t('financial.minimumDepositAmount', {
                            min_deposit: minDeposit.toFixed(2)
                          })
                        }
                      })}
                    />
                    <span style={{ color: 'red', display: 'block' }}>
                      {errors.amount && errors.amount.message}
                    </span>

                    <p />
                    {selectedPaymentHandle !== 'wire' && selectedPaymentHandle === 'bluepay' ? (
                      <span style={{ color: 'green' }}>
                        {`~ ${t(
                          'financial.youWillNeedToPay'
                        )} ${selectedPayment?.supportedCurrencies[0]?.currency?.abbreviation?.toUpperCase()}  ${(
                          watchAmount *
                          selectedPayment?.supportedCurrencies[0]?.currency?.exchange_rate
                        ).toFixed(2)}
                  ${t('financial.toReceive')} $ ${
                          watchAmount > 0
                            ? watchAmount *
                              selectedPayment?.supportedCurrencies[0]?.currency?.deposit_rate
                            : '0.00'
                        }
                    ${t('financial.inWallet')}`}
                      </span>
                    ) : (
                      ''
                    )}
                  </FormGroup>
                ) : (
                  ''
                )}

                {selectedPaymentHandle === 'wire' && (
                  <>
                    <FormGroup>
                      <Input
                        type="file"
                        name="file"
                        accept="image/png, image/jpeg, application/pdf"
                        required
                        innerRef={register({ required: t('financial.ReceiptIsRequired') })}
                      />
                      <FormText color="muted">{t('financial.UploadYourDepositReceipt')}</FormText>
                    </FormGroup>
                    <br />
                    {/* <Label>Choose either one bank to deposit</Label>
                    <br/> */}
                    {/* <Label>{t('financial.DepositDetail')}</Label> */}
                    {/* {Object.keys(selectedPayment.extra_details).map((detail) => {
                      return (
                        <Row>
                          <Col xs="12" md="6" style={{ fontWeight: 'bold' }}>
                            {_.startCase(detail)}
                          </Col>
                          <Col xs="12" md="6">
                            {selectedPayment.extra_details[detail]}
                          </Col>
                          <hr />
                        </Row>
                      )
                    })}
                    <hr /> */}
                    <Label>Bank 1 Details</Label>
                    <Row>
                      <Col xs="12" md="6" style={{ fontWeight: 'bold' }}>
                        BSB
                      </Col>
                      <Col xs="12" md="6">
                        034702
                      </Col>
                      <hr />
                    </Row>
                    <Row>
                      <Col xs="12" md="6" style={{ fontWeight: 'bold' }}>
                        Bank Name
                      </Col>
                      <Col xs="12" md="6">
                        Westpac
                      </Col>
                      <hr />
                    </Row>
                    <Row>
                      <Col xs="12" md="6" style={{ fontWeight: 'bold' }}>
                        Swift Code
                      </Col>
                      <Col xs="12" md="6">
                        WPACAU2S
                      </Col>
                      <hr />
                    </Row>
                    <Row>
                      <Col xs="12" md="6" style={{ fontWeight: 'bold' }}>
                        Bank Address
                      </Col>
                      <Col xs="12" md="6">
                        44 Market St Sydney
                      </Col>
                      <hr />
                    </Row>
                    <Row>
                      <Col xs="12" md="6" style={{ fontWeight: 'bold' }}>
                        Account Holder
                      </Col>
                      <Col xs="12" md="6">
                        Tradehall Pty Ltd
                      </Col>
                      <hr />
                    </Row>

                    <Row>
                      <Col xs="12" md="6" style={{ fontWeight: 'bold' }}>
                        Account Number
                      </Col>
                      <Col xs="12" md="6">
                        449975 (USD)
                      </Col>
                      <hr />
                    </Row>
                    <Row>
                      <Col xs="12" md="6" style={{ fontWeight: 'bold' }}>
                        Beneficiary Address:
                      </Col>
                      <Col xs="12" md="6">
                        9A NEUTRAL AVENUE , BIRRONG NSW 2143
                      </Col>
                      <hr />
                    </Row>

                    <hr />
                    <Label>Bank 2 Details</Label>
                    <Row>
                      <Col xs="12" md="6" style={{ fontWeight: 'bold' }}>
                        BSB
                      </Col>
                      <Col xs="12" md="6">
                        012055
                      </Col>
                      <hr />
                    </Row>
                    <Row>
                      <Col xs="12" md="6" style={{ fontWeight: 'bold' }}>
                        Bank Name
                      </Col>
                      <Col xs="12" md="6">
                        ANZ
                      </Col>
                      <hr />
                    </Row>

                    <Row>
                      <Col xs="12" md="6" style={{ fontWeight: 'bold' }}>
                        Swift Code
                      </Col>
                      <Col xs="12" md="6">
                        ANZBAU3M
                      </Col>
                      <hr />
                    </Row>
                    <Row>
                      <Col xs="12" md="6" style={{ fontWeight: 'bold' }}>
                        Bank Address
                      </Col>
                      <Col xs="12" md="6">
                        20 Martin Pl, Sydney NSW 2000
                      </Col>
                      <hr />
                    </Row>
                    <Row>
                      <Col xs="12" md="6" style={{ fontWeight: 'bold' }}>
                        Account Holder
                      </Col>
                      <Col xs="12" md="6">
                        Tradehall Pty Ltd
                      </Col>
                      <hr />
                    </Row>
                    <Row>
                      <Col xs="12" md="6" style={{ fontWeight: 'bold' }}>
                        Account Number
                      </Col>
                      <Col xs="12" md="6">
                        648117367 (AUD)
                      </Col>
                      <hr />
                    </Row>

                    <Row>
                      <Col xs="12" md="6" style={{ fontWeight: 'bold' }}>
                        Beneficiary Address:
                      </Col>
                      <Col xs="12" md="6">
                        9A NEUTRAL AVENUE , BIRRONG NSW 2143
                      </Col>
                      <hr />
                    </Row>
                  </>
                )}
                <div style={{ padding: 10 }} />

                {selectedPaymentHandle === 'mcpayment_credit_card' && (
                  <Card style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', marginTop: '15px' }}>
                    <CardBody style={{ padding: 16 }}>
                      <div style={{ display: 'flex' }}>
                        <AlertCircle
                          width="20px"
                          style={{ color: '#f9b600', marginRight: '5px' }}
                        />
                        <h5 style={{ color: '#f9b600' }}>{t('financial.IMPORTANTNOTE')} !</h5>
                      </div>
                      <p>1. {t('financial.ThisSubmissionWillNotBeDirectly')}</p>
                      <p>2. {t('financial.YouWouldStillNeedToEnter')}</p>
                      <p>3. {t('financial.MakeSureTheCreditCard')}</p>
                      <p>4. {t('financial.IfYouTypeDifferent')}</p>
                      <p>5. {t('financial.YouMayOnlyWithdrawYourFund')}</p>
                    </CardBody>
                  </Card>
                )}

                {/* hide checkbox and button when in multichain  */}
                {selectedPaymentHandle !== 'multichain' && (
                  <>
                    <FormGroup>
                      <div className="custom-control custom-checkbox mb-3">
                        <Input
                          className="custom-control-input"
                          id="terms_validation"
                          type="checkbox"
                          required
                        />
                        <Label className="custom-control-label" htmlFor="terms_validation">
                          {t('financial.iHaveReadAllInstructions')}
                        </Label>
                        <div className="invalid-feedback">
                          {t('financial.pleaseAgreeToTheTerms')}
                        </div>
                      </div>
                    </FormGroup>

                    {/* Disable when mcpayment maximum deposit in RM */}
                    <Button
                      color="primary btn-block"
                      type="submit"
                      style={{ maxWidth: 150, float: 'right' }}
                      disabled={watchAmount > maxDeposit ?? 9999999}
                    >
                      {isSubmitting ? t('financial.loading') : t('financial.submit')}
                    </Button>
                  </>
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      ) : (
        <>
          <Card>
            <CardBody className="p-4" style={{ backgroundColor: '#ffc4c4', borderRadius: '15px' }}>
              <ResponsiveCard>
                <ResponsiveParagraph style={{ color: '#963737', fontWeight: 'bold' }}>
                  {t('financial.YouNeedAtLeastOne')}
                </ResponsiveParagraph>
                <ResponsiveVerifyButton
                  color="primary"
                  onClick={() => {
                    navigate(`${process.env.PUBLIC_URL}/account#creditCard`)
                  }}
                >
                  {t('dashboard.verify')}
                </ResponsiveVerifyButton>
              </ResponsiveCard>
            </CardBody>
          </Card>

          {selectedPaymentHandle === 'mcpayment_credit_card' && (
            <Card style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', marginTop: '15px' }}>
              <CardBody style={{ padding: 16 }}>
                <div style={{ display: 'flex' }}>
                  <AlertCircle width="20px" style={{ color: '#f9b600', marginRight: '5px' }} />
                  <h5 style={{ color: '#f9b600' }}>{t('financial.IMPORTANTNOTE')} !</h5>
                </div>
                <p>1. {t('financial.YouNeedAtLeast')}</p>
                <p>2. {t('financial.MaximumDeposit2000USDPerTransaction')}</p>
                <p>3. {t('financial.DepositFeeForCreditCard')}</p>
              </CardBody>
            </Card>
          )}
        </>
      )}
    </>
  )
}

const AddressH6 = styled.h6`
  font-weight: 400 !important;
  margin-right: 5px;
  margin-top: 3px;

  @media (max-width: 575px) {
    font-size: 11px;
  }
`

export default ChooseAmount
